import { AppContext } from "@components/contexts/AppContext";
import { PAGE_CONFIG, TextBlock } from "@libs/products";
import { Flexbox, Heading, Icon, Text } from "@redsift/design-system";
import { rsiRedSift } from "@redsift/icons";
import { ReactElement, useContext } from "react";

const SmallLoginScreen = ({ LoginPanel }: { LoginPanel: ReactElement }) => {
  const ctx = useContext(AppContext);
  const product = ctx.getProduct();
  const config = PAGE_CONFIG[product] ?? PAGE_CONFIG["pulse-platform"];

  const { logos, PartnerBanner, title, body, Quote, Intro } = config;
  return (
    <Flexbox
      flexDirection="column"
      flex="1"
      background="white"
      alignItems="center"
      gap="32px"
    >
      <Flexbox
        alignSelf="flex-start"
        padding="12px"
        borderBottom="1px solid #A3A3A3"
        width="100%"
      >
        <Icon icon={rsiRedSift} size={{ width: 50 }} />
      </Flexbox>
      {logos
        ? logos.map((logo, index) => (
            <Icon key={index} icon={logo} size={{ width: 225 }} />
          ))
        : null}
      {PartnerBanner ? <PartnerBanner isSmallScreen /> : null}
      <Heading
        as="h1"
        fontSize="42px"
        lineHeight="55px"
        margin="0 32px 32px"
        style={{ textAlign: "center" }}
      >
        {title}
      </Heading>
      <Flexbox maxWidth="520px" flexDirection="column" padding="32px" flex="1">
        {LoginPanel}
      </Flexbox>
      {Intro ? <Intro isSmallScreen /> : null}
      {body ? (
        <Flexbox flexDirection="column">
          {body.map(({ title, smallTitle, description }) => (
            <TextBlock key={title.toString()}>
              <Text
                fontSize="24px"
                lineHeight="26px"
                fontWeight="600"
                color="error"
              >
                {smallTitle ?? title}
              </Text>
              <Text fontSize="16px" lineHeight="24px">
                {description}
              </Text>
            </TextBlock>
          ))}
        </Flexbox>
      ) : null}
      {Quote ? <Quote isSmallScreen /> : null}
    </Flexbox>
  );
};

export default SmallLoginScreen;
